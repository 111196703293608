"use strict";

// Class definition
var KTProjects = function () {
  var submitProjectActivation = function () {
    let button = document.getElementById("projects-update-activated")
    if(!button) {
      return;
    }

    button.onclick = function (event) {
      let checkedProjects =  $('.select-project-checkbox:checked')
      let ids = []
      checkedProjects.each(function() {
				ids.push($(this)[0].id);
			});

			console.log('checkedProjects: ', checkedProjects)

      $.ajax({
				url: '/update_activated',
				method: 'POST',
				data: {
					project_ids: ids,
					integration: 'asana'
				},
				success(data) {
				},
				error(e) {
					toastr.error(
						"Please try it again later.",
						"Something went wrong!",
						{timeOut: 1000, extendedTimeOut: 0, closeButton: true, closeDuration: 0}
					);
				}
			})
    }
  }

	var submitProjectActivationJira = function () {
    let button = document.getElementById("projects-update-activated-jira")
    if(!button) {
      return;
    }

    button.onclick = function (event) {
      let checkedProjects =  $('.select-project-checkbox:checked')
      let ids = []
      checkedProjects.each(function() {
				ids.push($(this)[0].id);
			});

			console.log('checkedProjects: ', checkedProjects)
			console.log('ids: ', ids)
      $.ajax({
				url: '/update_activated',
				method: 'POST',
				data: {
					project_ids: ids,
					integration: 'jira'
				},
				success(data) {
				},
				error(e) {
					toastr.error(
						"Please try it again later.",
						"Something went wrong!",
						{timeOut: 1000, extendedTimeOut: 0, closeButton: true, closeDuration: 0}
					);
				}
			})
    }
  }

	return {
		// Public functions
		init: function () {
      submitProjectActivation();
			submitProjectActivationJira();
		}
	};
}();

// On document ready
KTUtil.onDOMContentLoaded(function () {
	KTProjects.init();
});
