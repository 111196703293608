//= require js-cookie/src/js.cookie
//= require shepherd.js/dist/js/shepherd

var Abraham = new Object();

Abraham.tours = {};
Abraham.incompleteTours = [];
Abraham.startTour = function(tourName) {
  if (!Shepherd.activeTour) {
  	Abraham.tours[tourName].start();
  }
};
Abraham.startNextIncompleteTour = function() {
  if (Abraham.incompleteTours.length) {
    if (Abraham.incompleteTours[0] === "intro") {
      const introTour = Abraham.tours[Abraham.incompleteTours[0]];
      introTour.options = { exitOnEsc: true, keyboardNavigation: true, useModalOverlay: true }
      introTour.checkAndStart();  
      createWelcomeModal();
    } else {
      Abraham.tours[Abraham.incompleteTours[0]].options = { cancelIcon: { enabled: true }, tourName: 'checklist', classPrefix: 'checklist-', exitOnEsc: true, keyboardNavigation: true }
      Abraham.tours[Abraham.incompleteTours[0]].steps.forEach(function (step) {
        step.options.classes = 'shepherd-checklist-content'
        step.options.cancelIcon = { enabled: true }
      })
      const shepherdDiv = document.createElement('div');
      shepherdDiv.id = 'checklist-div'
      $.ajax({
        url: '/shepherd_div',
        method: 'GET',
        success(data) {
        }
      })
      Abraham.tours[Abraham.incompleteTours[0]].checkAndStart();
      const abraham = document.getElementById("step-1-description")
      abraham.appendChild(shepherdDiv)
      $('.shepherd-footer')[0].style.display = 'none';
    }

  }
};

var formatPeriod = function() {
  const period = document.getElementById('kt_content_container_edit').dataset.period;
  if (period === "weekly") {
    return ' each week'
  } else if (period === 'two weeks') {
    return 'every two weeks'
  } else {
    return 'each month'
  }
}

var createWelcomeModal = function(){
  const period = formatPeriod();
  const descriptionOne = document.createElement('div');
  descriptionOne.className = "shepherd-modal-description";
  const imageOne = document.createElement('img');
  imageOne.setAttribute('src', '/shepherd/report_generate.png')
  descriptionOne.appendChild(imageOne);
  const descriptionOneText = document.createElement('p');
  descriptionOneText.innerText = 'The report will automatically generate ' + period + ' which is what you set in this report’s settings.';
  descriptionOne.appendChild(descriptionOneText)
  const descriptionTwo = document.createElement('div');
  descriptionTwo.className = "shepherd-modal-description";
  const descriptionTwoText = document.createElement('p');
  descriptionTwoText.innerText = 'The new report will copy the text contents of the previous periods report which you can then update.';
  const imageTwo = document.createElement('img');
  imageTwo.setAttribute('src', '/shepherd/report-copy.png')
  descriptionTwo.appendChild(imageTwo);
  descriptionTwo.appendChild(descriptionTwoText);
  const descriptionThree = document.createElement('div');
  descriptionThree.className = "shepherd-modal-description";
  const descriptionThreeText = document.createElement('p');
  descriptionThreeText.innerText = 'Each new report will show updated project data from the Asana or Jira project it is linked to. The previous reports data will not change.';
  const imageThree = document.createElement('img');
  imageThree.setAttribute('src', '/shepherd/report-data.png')
  descriptionThree.appendChild(imageThree);
  descriptionThree.appendChild(descriptionThreeText);
  const abraham = document.getElementById("step-1-description")
  abraham.appendChild(descriptionOne)
  abraham.appendChild(descriptionTwo)
  abraham.appendChild(descriptionThree)

}

document.addEventListener("DOMContentLoaded", Abraham.startNextIncompleteTour);
document.addEventListener("turbolinks:load", Abraham.startNextIncompleteTour);

document.addEventListener('turbolinks:before-cache', function() {
  // Remove visible product tours
  document.querySelectorAll(".shepherd-element").forEach(function(el) { el.remove() });
  // Clear Abraham data
  Abraham.tours = {};
  Abraham.incompleteTours = [];
});

window.Abraham = Abraham;
import Shepherd from "shepherd.js";
window.Shepherd = Shepherd;
import Cookies from "js-cookie/src/js.cookie";
window.Cookies = Cookies;