"use strict";

import flatpickr from 'flatpickr'
// require("flatpickr/dist/flatpickr.css");

// Select2 - Select2 is a jQuery based replacement for select boxes: https://select2.org/
require('select2/dist/js/select2.full.min.js');
require('../vendors/plugins/select2.init.js');

// import select2 from 'select2';
// require("select2/dist/css/select2.css");

KTUtil.onDOMContentLoaded(function () {
  flatpickr("[data-behaviour='flatpickr']", {
    enableTime: false,
    dateFormat: "d/m/Y",
  });

  $("[data-behaviour='select2']").select2({
    placeholder: "Please select"
  });

  // $('.select-project-checkbox').click(function() {
  //   let projectId = this.id;
  //   $.ajax({
  //     method: 'POST',
  //     url: '/projects/' + projectId + '/update_activated',
  //     data: { activated: this.checked },
  //     success() {
  //     },
  //     error() {
  //     }
  //   })
  // });
})
