"use strict";

import ApexCharts from 'apexcharts';

// Class definition
var KTCustomCharts = function () {
  var apexChart;
  var customChartId;
  var chartType;
  var selectedChartBy;
  var selectedCount;
  var groupings = new Map();
  var projectId;
  var labels;
  var pieVisibility;
  var columnVisibility;
  var lineVisibility;
  var groupBy;

  var renderPieChart = function() {
    let display = $("#pie_chart_form").css("display");
    if(display === "none") {
      return;
    }

    let element = document.querySelector('[id^="apex_custom_chart_pie_"]')
    if(!element) {
      return;
    }

    if(apexChart) {
      apexChart.destroy();
    }
    let data = JSON.parse(element.dataset.data);
    labels = JSON.parse(element.dataset.labels);
    // selectedCount = $('#custom_chart_count_pie').val();
    selectedChartBy = $('#custom_chart_chart_by_pie').val()
    chartType = $(".custom_chart_selector_selected")[0].htmlFor.split("_").splice(-1)[0]

    var options = {
      series: data[0].data,
      chart: {
        type: 'donut',
      },
      labels: labels,
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            height: 350
          },
          legend: {
            position: 'bottom'
          }
        }
      }],
      noData: {
        text: 'No data found for these settings. Please try again.',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: '14px',
          fontFamily: undefined
        }
      }
      
    };

    var chart = new ApexCharts(element, options);
    chart.render();
    apexChart = chart;
  }

  var renderColumnChart = function() {
    let display = $("#column_chart_form").css("display");
    if(display === "none") {
      return;
    }

    let element = document.querySelector('[id^="apex_custom_chart_column_"]')
    if (!element) {
      return;
    }

    if(apexChart) {
      apexChart.destroy();
    }

    let data = JSON.parse(element.dataset.data);
    labels = JSON.parse(element.dataset.labels);

    // selectedCount = $('#custom_chart_count_column').val();
    selectedChartBy = $('#custom_chart_chart_by_column').val();
    selectedCount = $('#custom_chart_count_column').val();
    chartType = $(".custom_chart_selector_selected")[0].htmlFor.split("_").splice(-1)[0]
    var options = {
      series: data,
      chart: {
        type: 'bar',
        stacked: true,
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: labels,
        title: {
          text: 'Sprint',
        },
        labels: {
          style: {
              fontSize: '12px'
          }
        }
      },
      yaxis: {
        title: {
          text: ''
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        show: false,
        showForSingleSeries: true,
        position: 'bottom',
        formatter: function(seriesName, opts) {
          let name = seriesName
          if(name === null || name.startsWith("series-group-by") || name.startsWith("series")) {
            name = null
            Array.from(document.getElementsByClassName('apexcharts-legend')).forEach(legend => legend.style.display = "none" );
          } else {
            Array.from(document.getElementsByClassName('apexcharts-legend')).forEach(legend => legend.style.display = "flex" );
          }
          return [name]
        }
      },
    };

    var chart = new ApexCharts(element, options);
    chart.render();
    apexChart = chart;
    addGroupByForData();
  }

  var renderLineChart = function() {
    let display = $("#line_chart_form").css("display");
    if(display === "none") {
      return;
    }

    let element = document.querySelector('[id^="apex_custom_chart_line_"]')
    if (!element) {
      return;
    }

    if(apexChart) {
      apexChart.destroy();
    }

    let data = JSON.parse(element.dataset.data);
    labels = JSON.parse(element.dataset.labels);

    selectedChartBy = $('#custom_chart_chart_by_line').val();
    selectedCount = $('#custom_chart_count_column').val();
    chartType = $(".custom_chart_selector_selected")[0].htmlFor.split("_").splice(-1)[0]

    var options = {
      series: data,
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      xaxis: {
        categories: labels,
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        position: 'bottom',
        formatter: function(seriesName, opts) {
          let name = seriesName
          if(name === null || name.startsWith("series-group-by") || name.startsWith("series")) {
            name = null
            Array.from(document.getElementsByClassName('apexcharts-legend')).forEach(legend => legend.style.display = "none" );
          } else {
            Array.from(document.getElementsByClassName('apexcharts-legend')).forEach(legend => legend.style.display = "flex" );
          }
          return [name]
        }
      }
    };

    var chart = new ApexCharts(element, options);
    chart.render();
    apexChart = chart;
    addGroupByForData();
  }

  var retrieveUpdatedData = function() {
    let groupJson = JSON.stringify(Object.fromEntries(groupings))
    $.ajax({
      url: '/retrieve_chart_data',
      data: {
        project_id: projectId,
        count_field: selectedCount,
        chart_field: selectedChartBy,
        groupings: groupJson,
        group_by: groupBy
      },
      success(data) {
        if(chartType === "pie") {
          apexChart.updateOptions({
            series: JSON.parse(data)["data"][0]["data"],
            labels: JSON.parse(data)["labels"]
          })
        } else if (chartType === "column") {
          apexChart.updateOptions({
            series: JSON.parse(data)["data"],
            xaxis: {
              categories: JSON.parse(data)["labels"],
            },
            legend: {
              show: true,
              showForSingleSeries: true,
              position: 'bottom',
              formatter: function(seriesName, opts) {
                let name = seriesName
                if(name === null || (typeof name === "string" && name.startsWith("series"))) {
                  name = null
                } else {
                }
                return [name]
              }
            }
          })
          if(JSON.parse(data)["data"].length <= 1 && JSON.parse(data)["data"][0]["name"].startsWith("series")) {
            Array.from(document.getElementsByClassName('apexcharts-legend')).forEach(legend => legend.style.display = "none" );
          }
        } else if (chartType === "line") {
          apexChart.updateOptions({
            series: JSON.parse(data)["data"],
            xaxis: {
              categories: JSON.parse(data)["labels"]
            },
            legend: {
              show: true,
              showForSingleSeries: true,
              position: 'bottom',
              formatter: function(seriesName, opts) {
                let name = seriesName
                if(name === null || (typeof name === "string" && name.startsWith("series"))) {
                  name = null
                } else {
                }
                return [name]
              }
            }
          })
          if(JSON.parse(data)["data"].length <= 1 && JSON.parse(data)["data"][0]["name"].startsWith("series")) {
            Array.from(document.getElementsByClassName('apexcharts-legend')).forEach(legend => legend.style.display = "none" );
          }
        }

        labels = JSON.parse(data)["labels"]

        // let groupSelector = document.querySelectorAll('[id^="custom_chart_grouping_group_by_"]')
        // groupSelector.forEach(function(item, index, nodeList) {
        //   let element = $("#" + item.id)
        //   let groupingIndex = item.id.split("_").splice(-1)[0];
        //   if(groupingIndex === "1") {
        //     element.html("");
        //     JSON.parse(data)["group_options"].forEach(function(v) {
        //       element.append('<option value="' + v + '">' + v + '</option>');
        //     });
        //     element.select2();
        //   } else {
        //     let selectedLabel = $("#custom_chart_grouping_group_by_" + groupingIndex).val()
        //     if(selectedLabel) {
        //       selectedLabel.forEach(label => {
        //         groupings.delete(label);
        //       });
        //     }

        //     let group = $("#grouping_form_" + groupingIndex)
        //     group.remove();
        //   }
        // });
      },
      error(e) {
        toastr.error(
          "Please try it again later.",
          "Something went wrong!",
          {timeOut: 1000, extendedTimeOut: 0, closeButton: true, closeDuration: 0}
        );
      }
    });
  }

  var addGroupByForData = function() {
    $(".group_by_selector").on("select2:select", function (e) {
      groupBy = e.target.value;

      retrieveUpdatedData();
    })
  }

	var updateChartData = function() {
    $('.custom_chart_chart_by').on('select2:select', function (e) {
      selectedChartBy = e.params.data.id;

      retrieveUpdatedData();
    })

    $('.custom_chart_count_by').on('select2:select', function (e) {
      selectedCount = e.params.data.id;

      retrieveUpdatedData();
    })
	}

  var updateChartDataWithGrouping = function() {
    $("select.grouping_selector").on('select2:select', function (e) {
      let groupingIndex = e.delegateTarget.id.split("_").splice(-1)[0]
      let selectedLabel = e.params.data.text
      let groupingName = $("#custom_chart_grouping_group_name_" + chartType + "_" + groupingIndex).val()
      // TODO: If groupingName blank add an error on name field saying please don't leave blank
      groupings.set(selectedLabel, groupingName)
      retrieveUpdatedData();
    })
  }

  var addGroupByElement = function() {
    $('.chart_add_grouping').on("click", function (event) {
      event.preventDefault();
      let newGroupingForms = $("select.columns_in_group:last")[0];
      let oldIndex = 1;
      if(newGroupingForms) {
        oldIndex = newGroupingForms.id.split("_").splice(-1)[0];
      }
      let index = parseInt(oldIndex) + 1
      $.ajax({
        url: '/add_grouping',
        method: 'POST',
        data: {
          labels: labels,
          index: index,
          chart_type: chartType
        },
        success(data) {
          updateChartDataWithGrouping();
          removeGroupByElement(index, chartType);
        },
        error(e) {
          toastr.error(
						"Please try it again later.",
						"Something went wrong!",
						{timeOut: 1000, extendedTimeOut: 0, closeButton: true, closeDuration: 0}
					);
        }
      })
    })
  }

  var removeGroupByElement = function(index, chartType) {
    let button = document.getElementById('pie_chart_group_by_remove_' + index);

    button.onclick = function (event) {
      event.preventDefault();
      let selectedLabel = $("#custom_chart_grouping_group_by_" + chartType + "_" + index).val()
      selectedLabel.forEach(label => {
        groupings.delete(label);
      });

      // let groupJson = JSON.stringify(Object.fromEntries(groupings))
      retrieveUpdatedData();
      // $.ajax({
      //   url: '/retrieve_chart_data',
      //   data: {
      //     project_id: projectId,
      //     count_field: selectedCount,
      //     chart_field: selectedChartBy,
      //     groupings: groupJson
      //   },
      //   success(data) {
      //     apexChart.updateOptions({
      //       series: JSON.parse(data)["data"],
      //       labels: JSON.parse(data)["labels"]
      //     })
      //     labels = JSON.parse(data)["labels"]
      //   },
      //   error(e) {
      //     toastr.error(
			// 			"Please try it again later.",
			// 			"Something went wrong!",
			// 			{timeOut: 0, extendedTimeOut: 0, closeButton: true, closeDuration: 0}
			// 		);
      //   }
      // })

      let group = document.getElementById("grouping_form_" + index)
      group.remove();
    }
  }

  var submitCustomChart = function (chartType) {
    let chartName = $("#custom_chart_title_" + chartType).val();
    let groupJson = JSON.stringify(Object.fromEntries(groupings));
    selectedCount = $('#custom_chart_count_' + chartType).val();
    selectedChartBy = $('#custom_chart_chart_by_' + chartType).val();
    groupBy = $("#custom_chart_group_by_" + chartType).val();

    $.ajax({
      url: '/custom_charts',
      method: 'POST',
      data: {
        project_id: projectId,
        title: chartName,
        chart_type: chartType,
        count_field: selectedCount,
        chart_field: selectedChartBy,
        groupings: groupJson,
        group_by: groupBy
      },
      success(data) {

      },
      error(e) {
        toastr.error(
          "Please try it again later.",
          "Something went wrong!",
          {timeOut: 0, extendedTimeOut: 0, closeButton: true, closeDuration: 0}
        );
      }
    })
  }

  var saveCustomChart = function () {
    let pieButton = document.getElementById("chart_submit_pie");

    $(pieButton).on('click', function (event) {
      event.preventDefault();
      submitCustomChart('pie');
    });

    let columnButton = document.getElementById("chart_submit_column");

    $(columnButton).on('click', function (event) {
      event.preventDefault();
      submitCustomChart('column');
    });  

    let lineButton = document.getElementById("chart_submit_line");

    $(lineButton).on('click', function (event) {
      event.preventDefault();
      submitCustomChart('line');
    });    
  }

  var submitUpdateCustomChart = function (chartType) {
    let chartName = $("#custom_chart_title_" + chartType).val();
    let groupJson = JSON.stringify(Object.fromEntries(groupings));
    selectedCount = $('#custom_chart_count_' + chartType).val();
    selectedChartBy = $('#custom_chart_chart_by_' + chartType).val();
    groupBy = $("#custom_chart_group_by_" + chartType).val();

    $.ajax({
      url: '/custom_charts/' + customChartId,
      method: 'PATCH',
      data: {
        project_id: projectId,
        title: chartName,
        chart_type: chartType,
        count_field: selectedCount,
        chart_field: selectedChartBy,
        groupings: groupJson,
        group_by: groupBy
      },
      success(data) {
        toastr.success(
						"Chart settings have been updated",
						{timeOut: 1000, extendedTimeOut: 0, closeButton: true, closeDuration: 0}
					);
      },
      error(e) {
      }
    })

  }

  var updateCustomChart = function () {
    let pieButton = document.getElementById("chart_submit_pie");

    $(pieButton).on('click', function (event) {
      event.preventDefault();
      submitUpdateCustomChart('pie');
    });

    let columnButton = document.getElementById("chart_submit_column");

    $(columnButton).on('click', function (event) {
      event.preventDefault();
      submitUpdateCustomChart('column');
    });  

    let lineButton = document.getElementById("chart_submit_line");

    $(lineButton).on('click', function (event) {
      event.preventDefault();
      submitUpdateCustomChart('line');
    });    
  }

  var preFillGroupingData = function () {
    let groupingData = document.getElementById("custom_chart_by_body_" + chartType + "_1").dataset.groupings
    let groupingJSON = JSON.parse(groupingData)

    let groupingsToDisplay = {};
    customChartId = $(document.querySelector('[id^="custom_chart_container_'))[0].id.split("_").splice(-1)[0]
    for (const [key, value] of Object.entries(groupingJSON)) {
      if(groupingsToDisplay.hasOwnProperty(value)) {
        groupingsToDisplay[value].push(key)
      } else {
        groupingsToDisplay[value] = [key]
      }
    }
    let index = 1
    for (var obj in groupingsToDisplay) {
      if(index === 1) {
        $("#custom_chart_grouping_group_name_" + chartType + "_1").val(obj)
        $("#custom_chart_grouping_group_by_" + chartType + "_1").val(groupingsToDisplay[obj]).trigger('change')
        groupingsToDisplay[obj].forEach(selectedLabel => {
          groupings.set(selectedLabel, obj);
        });
      } else {
        groupingsToDisplay[obj].forEach(selectedLabel => {
          groupings.set(selectedLabel, obj);
        });
        $.ajax({
          url: '/add_grouping',
          method: 'POST',
          data: {
            labels: labels,
            index: index,
            group_name: obj,
            groups: groupingsToDisplay[obj],
            id: customChartId,
            chart_type: chartType
          },
          success(data) {
          },
          error(e) {
            toastr.error(
              "Please try it again later.",
              "Something went wrong!",
              {timeOut: 0, extendedTimeOut: 0, closeButton: true, closeDuration: 0}
            );
          }
        })
      }
      index += 1
    }

    let groupBy = document.getElementById("custom_chart_group_by_" + chartType)
    if(groupBy) {
      let value = groupBy.dataset.value;
      $(groupBy).val(value).trigger('change');
    }
  }

  var changeChartSelection = function () {
    let selectors = $(".custom_chart_selector")

    selectors.on('click', function(event){
      event.preventDefault();
      $(".custom_chart_selector_selected").removeClass("custom_chart_selector_selected btn-shadow")
      let selectedChart = $("#" + event.currentTarget.id)
      selectedChart.addClass("custom_chart_selector_selected btn-shadow")
      chartType = $(".custom_chart_selector_selected")[0].htmlFor.split("_").splice(-1)[0]
      $.ajax({
        url: '/update_chart_type',
        method: 'POST',
        data: {
          chart_type: chartType,
        },
        success(data) {
          if(chartType === "pie") {
            columnVisibility = "none";
            pieVisibility = "block";
            lineVisibility = "none";
            renderPieChart();
            $("[data-behaviour='select2']").select2();
          } else if(chartType === "column") {
            pieVisibility = "none";
            columnVisibility = "block";
            lineVisibility = "none";
            renderColumnChart();
            $("[data-behaviour='select2']").select2();
          } else if(chartType == "line") {
            pieVisibility = "none";
            columnVisibility = "block";
            lineVisibility = "block";
            renderLineChart();
            $("[data-behaviour='select2']").select2();
          }
        },
        error(e) {
          toastr.error(
						"Please try it again later.",
						"Something went wrong!",
						{timeOut: 0, extendedTimeOut: 0, closeButton: true, closeDuration: 0}
					);
        }
      })
    })
  }

	return {
		// Public functions
		init: function () {
      // let element = document.querySelector('[id^="apex_custom_chart_"]');
      let button = document.getElementById("kt_custom_chart_submit");
      let newForm = document.querySelector('[id^="custom_chart_form_');
      let editForm = document.querySelector('[id^="edit_custom_chart_');
      let chartSelector = $(".custom_chart_selector_selected")[0];
      if(chartSelector) {
        chartType = chartSelector.htmlFor.split("_").splice(-1)[0]
      }

      pieVisibility = $("#pie_chart_form").css("display");
      columnVisibility = $("#column_chart_form").css("display");
      lineVisibility = $("#line_chart_form").css("display");
      selectedChartBy = "section";
      selectedCount = "tasks";

      
      changeChartSelection();
      if(editForm) {
        projectId = editForm.id.split('_').slice(-1)[0];
        preFillGroupingData();
        updateCustomChart();
      } else if(newForm) {
        projectId = newForm.id.split('_').slice(-1)[0];
        saveCustomChart();
      }

      // if(!apexChart) {
        if(pieVisibility === "block") {
          renderPieChart();
        } else if(columnVisibility === "block") {
          renderColumnChart();
        } else if(lineVisibility === "block") {
          renderLineChart();
        }
      // }

      if(apexChart) {
        updateChartData();
        addGroupByElement();
        updateChartDataWithGrouping();
      }
		}
	};
}();

// On document ready
KTUtil.onDOMContentLoaded(function () {
	KTCustomCharts.init();
});
