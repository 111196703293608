"use strict";

import moment from 'moment';
import ApexCharts from 'apexcharts';

// Class definition
var KTBurndownChart = function () {
  var apexChart;;
  var renderChart = function() {
    let element = document.getElementById('kt_apexcharts_1');

    let data = JSON.parse(element.dataset.data);
    console.log('data in javascript')
    console.log(data)
    var xaxisCategories = JSON.parse(element.dataset.xaxisCategories);
    var height = parseInt(KTUtil.css(element, 'height'));
    var labelColor = KTUtil.getCssVariableValue('--bs-gray-500');
    var borderColor = KTUtil.getCssVariableValue('--bs-gray-200');
    var baseColor = KTUtil.getCssVariableValue('--bs-primary');
    var secondaryColor = KTUtil.getCssVariableValue('--bs-gray-300');
  
    if (!element) {
        return;
    }
  
    var options = {
      series: [{
        name: "Tasks",
        data: data
        // data: data
      }],
        chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      xaxis: {
        categories: xaxisCategories,
        title: {
          text: 'Day',
        },
        labels: {
          style: {
              colors: labelColor,
              fontSize: '12px'
          }
        }
      },
      yaxis: {
        min: 0,
        title: {
          text: 'Remaining tasks'
        },
        labels: {
          style: {
              colors: labelColor,
              fontSize: '12px'
          }
        }
      }
    };
  
    var chart = new ApexCharts(element, options);
    chart.render();
    apexChart = chart;
  }

	var updateChart = function() {
		var periodSelect = document.getElementById('select-period');
    var periodStart = periodSelect.value;
    var periodLength = periodSelect.dataset.sprintLength;
    var projectId = periodSelect.dataset.project;

    let currentSprintStart = periodStart;
    for (let i = 0; i < 20; i++) {
      let previousSprintStart = moment(currentSprintStart).subtract(parseInt(periodLength), 'days').format("DD MMM YYYY")
      currentSprintStart = previousSprintStart
      var newOption = new Option(previousSprintStart, previousSprintStart, false, false);
      periodSelect.append(newOption);
    }

    $('#select-period').on('select2:select', function (e) {
      var data = e.params.data;
      $.ajax({
        url: '/projects/' + projectId + '/burndown',
        data: { startDate: data.text },
        success(data) {
          console.log('successHERE')
          console.log(JSON.parse(data))
          apexChart.updateSeries([{
            name: "Tasks",
            data: JSON.parse(data)
          }])

        },
        error() {
          console.log('error')
        }
      })
    });
	}

	return {
		// Public functions
		init: function () {
      let element = document.getElementById('kt_apexcharts_1');

      if(element) {
        renderChart();
        updateChart();
      }
		}
	};
}();

// On document ready
KTUtil.onDOMContentLoaded(function () {
	KTBurndownChart.init();
});
