"use strict";

// Class definition
var KTScrumChart = function () {
  var dismissAlert = function() {
    let element = document.getElementById('scrum-alert-dismiss');

		if(!element) {
			return;
		}

    element.onclick = function (event){
      let projectId = element.dataset.projectId
      let chart = element.dataset.chart

      $.ajax({
				url: '/projects/' + projectId +  '/dismiss_alert',
				method: 'POST',
				data: {
					chart: chart
				},
				success(data) {
				},
				error(e) {
					toastr.error(
						"Please try it again later.",
						"Something went wrong!",
						{timeOut: 1000, extendedTimeOut: 0, closeButton: true, closeDuration: 0}
					);
				}
			})
    }
  }

	return {
		// Public functions
		init: function () {
      dismissAlert();
		}
	};
}();

// On document ready
KTUtil.onDOMContentLoaded(function () {
	KTScrumChart.init();
});
