"use strict";

import ApexCharts from 'apexcharts';
import DataTable from 'datatables.net';
// Class definition
var KTReports = function () {
  var datatable;

  var initDatatable = () => {
    datatable = $('#kt_datatable_report_index').DataTable({
      info: false,
      lengthChange: false
    })

    var searchFilter = $('#kt_datatable_report_index_filter').children();
    searchFilter.addClass('dataTables_filter_label');
    var input = searchFilter.children('input')
    input.addClass('form-control');
    input.attr('placeholder', 'Search Reports');

  }

  var initCKEditor = function () {
    let reportEditors = document.querySelectorAll('[id^="ckeditor_balloon_"]');

    reportEditors.forEach(element => {
      let fieldData = element.id.split('_')
      let reportDetailId = fieldData.at(-1);
      let editedField = fieldData.at(-2);
      if(element.children[0].innerHTML === '&nbsp;') {
        element.removeChild(element.children[0])
      }

      BalloonEditor
        .create(element, {})
        .then(editor => {
            editor.ui.view.editable.element.style.height = '100px';
            $(editor.ui.view.editable.element).on('blur', () => {
              return saveData( editor.getData(), reportDetailId, editedField )
            });
        })
        .catch(error => {
            console.error(error);
        });
      });
    
  }

  var renderTaskByStatusChart = function () {
    let element = document.querySelector('[id^="report_task_by_status"]')
    if(!element) {
      return;
    }
    let data = JSON.parse(element.dataset.data);
    let labels = JSON.parse(element.dataset.labels);
    let chartOption = {
      type: 'donut',
      height: '350px'
    }
    data = data[0].data

    var options = {
      series: data,
      chart: chartOption,
      labels: labels,
      legend: {
        position: 'bottom',
        horizontalAlign: 'center'
      },
      responsive: [{
        breakpoint: 480,
        options: {
          // chart: {
          //   height: 250
          // },
          pie: {
            donut: {
              size: '85%'
            }
          },
        }
      }],
    };
    var chart = new ApexCharts(element, options);
    chart.render();
  }

  var renderCompletedByAssigneeChart = function () {
    let element = document.querySelector('[id^="report_task_by_assignee"]')
    if(!element) {
      return
    }
    let data = JSON.parse(element.dataset.data);

    let labels = JSON.parse(element.dataset.labels);
    // data = data[0].data
    var options = {
      series: data,
      chart: {
        type: 'bar',
        stacked: true,
        height: '350px'
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: true
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: labels,
        title: {
          text: 'Sprint',
        },
        labels: {
          style: {
              fontSize: '12px'
          }
        }
      },
      yaxis: {
        title: {
          text: ''
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        position: 'bottom',
        formatter: function(seriesName, opts) {
          let name = seriesName
          if(name === null || name.startsWith("series-group-by")) {
            name = "Unassigned"
          } else if(name.startsWith("series")) {
            name = selectedCount
          }
          return [name]
        }
      },
    };
    var chart = new ApexCharts(element, options);
    chart.render();
  }

  var projectSelection = function () {
    var button = document.getElementById('report-project-selection')
    if(!button) {
      return;
    }

    button.onclick = function (event) {
      let selectedProject = $("input[type='radio'][name='report-project-selection']:checked");

      $.ajax({
        url: '/select_project',
        method: 'POST',
        data: {
          project_id: selectedProject.val(),
          integration: selectedProject[0].dataset.integration,
          type: selectedProject[0].dataset.type 
        },
        success() {},
        error(e) {
          toastr.error(
						"Please try it again later.",
						"Something went wrong!",
						{timeOut: 1000, extendedTimeOut: 0, closeButton: true, closeDuration: 0}
					);
        }
      })
    }
  }

  var saveData = function (data, reportDetailId, editedField) {
    $.ajax({
      url: '/report_details/' + reportDetailId,
      method: 'PATCH',
      data: {
        field: editedField,
        data: data
      },
      success(data) {
        toastr.success(
          "Report updated",
          {timeOut: 1000, extendedTimeOut: 0, closeButton: true, closeDuration: 0}
        );
        let statusIcon = document.getElementById(editedField)
        $(statusIcon).removeClass('report-field-status-unedited')
        $(statusIcon).removeClass('report-field-status-copied')
        $(statusIcon).addClass('report-field-status-edited')
        updateOnboardingChecklist(statusIcon)
      },
      error(e) {
        toastr.error(
          "Please try it again later.",
          "Something went wrong!",
          {timeOut: 1000, extendedTimeOut: 0, closeButton: true, closeDuration: 0}
        );
      }
    })
    return true;
  }

  var updateOnboardingChecklist = function(editedField) {
    if(editedField.id === 'teamlead') {
      const teamLeadChecklist = document.getElementById('checklist-team-lead');
      if(teamLeadChecklist && !teamLeadChecklist.classList.contains('completed')) {
        teamLeadChecklist.classList.add('completed')
        teamLeadChecklist.firstElementChild.classList.remove('badge-incomplete');
        teamLeadChecklist.firstElementChild.classList.add('badge-completed');
        const icon = document.createElement('i')
        icon.classList.add('bi', 'bi-check-lg', 'bi-check-primary');
        teamLeadChecklist.firstElementChild.append(icon);
        var teamLeadPopover = document.getElementById('team-lead-popover');
        var popover = bootstrap.Popover.getOrCreateInstance(teamLeadPopover);
        popover.hide();
      }
    } else {
      const otherSectionChecklist = document.getElementById('checklist-other-sections');
      if(!otherSectionChecklist.classList.contains('completed')) {
        otherSectionChecklist.classList.add('completed')
        otherSectionChecklist.firstElementChild.classList.remove('badge-incomplete');
        otherSectionChecklist.firstElementChild.classList.add('badge-completed');
        const icon = document.createElement('i')
        icon.classList.add('bi', 'bi-check-lg', 'bi-check-primary');
        otherSectionChecklist.firstElementChild.append(icon);
      }
    }
  }

  var editReportSettings = function () {
    $("#report-actions").on('select2:select', function (e) {
      let reportId = this.dataset.reportId
      let action = e.params.data.id
      let url = ''
      if(action === "edit") {
        url = '/reports/' + reportId + '/edit'
      } else if(action === "download") {
        url = document.location.href + '.pdf'
      } 
      document.location.href = url
    })
  }

  var reportDetailPeriodSelection = function () {
    $("#report-period-selector").on('select2:select', function (e) {
      let reportDetailId = e.params.data.id
      let url = '/report_details/' + reportDetailId + '/edit'
      document.location.href = url
    })
  }

	return {
		// Public functions
		init: function () {
      initDatatable();
      initCKEditor();
      renderTaskByStatusChart();
      renderCompletedByAssigneeChart();
      projectSelection();
      editReportSettings();
      reportDetailPeriodSelection();
		}
	};
}();

// On document ready
KTUtil.onDOMContentLoaded(function () {
	KTReports.init();
});
