"use strict";

// Class definition
var KTGeneralLayout = function () {
  var populateProjectSelector = function () {
    let selector = $("#select-project")
    if(!selector) {
      return;
    }
    let selectedProject = selector[0].dataset.selectedProject

    selector.val(selectedProject).trigger('change');
  }

  var changeSelectedProject = function () {
    $("#select-project").on('select2:select', function (e) {
      let projectId = e.params.data.id.split('-')[1]

      let integration = e.params.data.element.dataset.integration
      $.ajax({
        url: '/change_project',
        method: 'POST',
        data: {
          project_id: projectId,
          integration: integration
        },
        success(data) {
        },
        error(e) {
          toastr.error(
						"You may be trying to access a project you have not activated or you do not have access to. Please try again.",
						"Something went wrong!",
						{timeOut: 1000, extendedTimeOut: 0, closeButton: true, closeDuration: 0}
					);
        }
      })
    });
  }

  var enableSignUp = function () {
    $("#sign-up-terms-conditions-checkbox").on('change', function (e) {
      e.preventDefault();
      $("#kt_free_trial_submit")[0].disabled = !$("#kt_free_trial_submit")[0].disabled
    })
  }

  var triggerManualSync = function () {
    let button = document.getElementById('manual-sync-button');

    button.onclick = function (event) {
      let container = document.getElementById('manual-sync-container')
      $(container).html('<a disabled="disabled" data-disabled-with="disabled" class="btn btn-lg btn-info fw-bolder disabled" data-remote="true" rel="nofollow" data-method="post" href="/manual_sync">Syncing in progress</a>')
    }
  }

  var hideSearchOnSelect = function() {
    let formSelects = document.querySelectorAll('.form-select');
    formSelects.forEach(element => {
      if(element.dataset.behaviour === "select2") {
        if(element.dataset.hideSearch === 'true') {
          $(element).select2({
            minimumResultsForSearch: Infinity
          })
        }
      }
    })
  }

	return {
		// Public functions
		init: function () {
      enableSignUp();
      populateProjectSelector();
      changeSelectedProject();
      triggerManualSync();
      hideSearchOnSelect();
		}
	};
}();

// On document ready
KTUtil.onDOMContentLoaded(function () {
	KTGeneralLayout.init();
});
