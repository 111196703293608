"use strict";

import ApexCharts from 'apexcharts';

// Class definition
var KTDashboards = function () {
  var enableSaveButton = function () {
    $('input[name=addDashboardRow]').on('click', function (event) {
      let button = $('#add-dashboard-row')
      button.removeClass('disabled')
    })
  }

  var renderCustomChart = function (index, column) {
    let element = document.querySelector('[id^="apex_custom_chart_' + index + '_' + column + '"]')
    if(!element) {
      return;
    }
    let data = JSON.parse(element.dataset.data);
    let labels = JSON.parse(element.dataset.labels);

    let chartType = element.id.split("_").splice(-1)[0];
    let chartOption = {};
    if(chartType === "pie") {
      chartOption = {
        type: 'donut',
        height: 350
      }
      data = data[0].data
    } else if(chartType === "column") {
      chartOption = {
        type: 'bar',
        stacked: true,
        height: 350
      }
    } else if(chartType === "line") {
      chartOption = {
        type: 'line',
        height: 350
      }
    }

    var options = {
      series: data,
      chart: chartOption,
      labels: labels,
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            height: 350
          },
          legend: {
            position: 'bottom'
          }
        }
      }],
      legend: {
        formatter: function(seriesName, opts) {
          let name = seriesName
          if(name === null || name.startsWith("series-")) {
            name = "No data"
          }
          return [name]
        }
      }
    };
    var chart = new ApexCharts(element, options);
    chart.render();
  }

  var renderBurnupChart = function (index, column) {
    let element = document.querySelector('[id^="apex_Burnup_chart_' + index + '_' + column + '"]')
    if(!element) {
      return;
    }
    let data = JSON.parse(element.dataset.data);
    let labels = JSON.parse(element.dataset.labels);

    var options = {
      series: data[0].data,
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      xaxis: {
        categories: labels,
        title: {
          text: 'Sprint',
        },
      },
      yaxis: {
        // min: 0,
        title: {
          text: 'Tasks completed'
        },
      }
    };

    var chart = new ApexCharts(element, options);
    chart.render();
  }

  var renderBurndownChart = function (index, column) {
    let element = document.querySelector('[id^="apex_Burndown_chart_' + index + '_' + column + '"]')
    if(!element) {
      return;
    }
    let data = JSON.parse(element.dataset.data);
    let labels = JSON.parse(element.dataset.labels);

    var options = {
      series: data[0].data,
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      xaxis: {
        categories: labels,
        title: {
          text: 'Sprint',
        },
      },
      yaxis: {
        // min: 0,
        title: {
          text: 'Tasks completed'
        },
      }
    };

    var chart = new ApexCharts(element, options);
    chart.render();
  }

  var renderVelocityChart = function (index, column) {
    let element = document.querySelector('[id^="apex_Velocity_chart_' + index + '_' + column + '"]')
    if(!element) {
      return;
    }
    let data = JSON.parse(element.dataset.data);
    let labels = JSON.parse(element.dataset.labels);

    var options = {
      series: data,
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: labels,
        title: {
          text: 'Sprint',
        },
      },
      yaxis: {
        title: {
          text: ''
        }
      },
      fill: {
        opacity: 1
      }
    };

    var chart = new ApexCharts(element, options);
    chart.render();
  }

  var selectChart = function () {
    $("select.dashboard-chart-selector").on('select2:select', function (e) {
      let selectedChart = e.params.data.id;
      let row = e.currentTarget.closest('.row')
      let index = row.id.split("_").splice(-1)[0]
      let row_type = row.id.split("_").splice(1, 1)[0]
      let column = e.currentTarget.id.split("-").splice(-1)[0]

      $.ajax({
        url: '/add_chart',
        method: 'POST',
        data: {
          selected_chart: selectedChart,
          index: index,
          column: column,
          row_type: row_type
        },
        success(responseData) {
          renderCustomChart(index, column);
          renderBurnupChart(index, column);
          renderBurndownChart(index, column);
          renderVelocityChart(index, column);
        },
        error(e) {
          toastr.error(
						"Please try it again later.",
						"Something went wrong!",
						{timeOut: 1000, extendedTimeOut: 0, closeButton: true, closeDuration: 0}
					);
        }
      });

    });
  }

  var addDashboardRow = function () {
    let button = $("#add-dashboard-row")

    button.on('click', function(event) {
      event.preventDefault();
      let value = $('input[name=addDashboardRow]:checked')[0].id.split("_")[1]
      let index = 1;
      let rows = document.querySelectorAll('[id^="dashboardRow_"]')
      if(rows.length >= 1) {
        let lastIndex = [...rows].pop().id.split("_").splice(-1)[0]
        index += parseInt(lastIndex)
      }
      $.ajax({
        url: '/add_dashboard_row',
        method: 'POST',
        data: {
          row_type: value,
          index: index
        },
        success(data) {
          selectChart();
          deleteRow();
        },
        error(e) {
          toastr.error(
						"Please try it again later.",
						"Something went wrong!",
						{timeOut: 1000, extendedTimeOut: 0, closeButton: true, closeDuration: 0}
					);
        }
      });
    })
  }

  var saveDashboard = function () {
    let button = document.getElementById("kt_dashboard_submit");
    if(!button) {
      return
    }

    button.onclick = function (event) {
      event.preventDefault();
      let rows = document.querySelectorAll('[id^="dashboardRow_"]')
      let dashboardLayout = []
      rows.forEach(function(item, index, nodeList) {
        let rowIndex = item.id.split("_").splice(-1)[0]
        let rowType = item.id.split("_")[1]
        let chartType = $(item.querySelectorAll(".apex-chart-container")[0]).children()[0].dataset.type
        if(rowType === 'single') {
          let cardBodyId = item.querySelectorAll(".card-chart")[0].id.split("-")
          let chartId = cardBodyId
          let chartName = item.querySelectorAll("select.form-select")[0].value
          if(cardBodyId.length > 1) {
            chartId = cardBodyId.splice(-1)[0]
          } else {
            chartId = cardBodyId[0]
          }
          let column = 1
          dashboardLayout.push({
            row_index: rowIndex,
            row_type: rowType,
            selected_chart: chartId,
            column: column,
            chart_type: chartType,
            chart_name: chartName
          })
        } else {
          // TODO: make row layouts for double together 
          let columns = item.querySelectorAll(".chart-container")
          let selectedCharts = []
          columns.forEach(function(columnItem, index, nodeList) {
            if($(columnItem).children()[1] !== undefined) {
              let cardBodyId = $(columnItem).children()[1].id.split("-")
              let chartId = cardBodyId
              let chartType = $(columnItem.querySelectorAll(".apex-chart-container")[0]).children()[0].dataset.type
              let chartName = item.querySelectorAll("select.form-select")[index].value
              if(cardBodyId.length > 1) {
                chartId = cardBodyId.splice(-1)[0]
              } else {
                chartId = cardBodyId[0]
              }
              let column = columnItem.id.split("-")[1]
              selectedCharts.push({
                chart: chartId,
                column: column,
                chart_type: chartType,
                chart_name: chartName
              })
            }
          })
          dashboardLayout.push({
            row_index: rowIndex,
            row_type: rowType,
            selected_charts: selectedCharts
          })
        }
      })

      let dashboardTitle = $("#dashboard_title").val()

      $.ajax({
        url: '/dashboards',
        method: 'POST',
        data: {
          title: dashboardTitle,
          layout: dashboardLayout
        },
        success(data) {
        },
        error(e) {
          toastr.error(
						"Please try it again later.",
						"Something went wrong!",
						{timeOut: 1000, extendedTimeOut: 0, closeButton: true, closeDuration: 0}
					);
        }
      });
    }
  }

  var displaySelectedCharts = function () {
    let customCharts = document.querySelectorAll('[id^="apex_custom_chart_"]');
    customCharts.forEach(function(item, index, nodeList) {
      let chartType = item.dataset.type;
      if(!$(item).parents('.card-chart.custom-chart')[0]) {
        return;
      }
      let chartId = $(item).parents('.card-chart.custom-chart')[0].id.split("-").splice(-1)[0]
      let chartOption = {};

      if(chartType === "pie") {
        chartOption = {
          type: 'donut',
          height: 350
        }
      } else if(chartType === "column") {
        chartOption = {
          type: 'bar',
          stacked: true,
          height: 350
        }
      } else if(chartType === "line") {
        chartOption = {
          type: 'line'
        }
      }
      var options = {
        series: [],
        chart: chartOption,
        labels: [],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              height: 350
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      };
      var chart = new ApexCharts(item, options);
      chart.render();

      $.ajax({
        url: '/get_data',
        data: {
          chart_id: chartId
        },
        dataType: 'json',
        success(data) {
          if(chartType === "pie") {
            chart.updateOptions({
              series: data.data[0].data,
              labels: data.labels
            })
          } else if (chartType === "column") {
            chart.updateOptions({
              series: data.data,
              xaxis: {
                categories: data.labels,
              },
            })
          } else if (chartType === "line") {
            chart.updateOptions({
              series: data.data,
              xaxis: {
                categories: data.labels
              },
            })
          }
        },
        error(e) {
          toastr.error(
            "Please try it again later.",
            "Something went wrong!",
            {timeOut: 1000, extendedTimeOut: 0, closeButton: true, closeDuration: 0}
          );
        }
      });
      // Render pie with data and then update with retrieve data 
    })

    let scrumCharts = document.querySelectorAll('[id^="apex_scrum_"]');
    scrumCharts.forEach(function(item, index, nodeList) {
      let chartType = item.dataset.type;
      let chartId = $(item).parents('.card-chart.scrum-chart')[0].id.split("-").splice(-1)[0]
      let chartOption = {};
      if(chartType === "pie") {
        chartOption = {
          type: 'donut',
          height: 350
        }
      } else if(chartType === "column") {
        chartOption = {
          type: 'bar',
          stacked: true,
          height: 350
        }
      }
      var options = {
        series: [],
        chart: chartOption,
        labels: [],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              height: 350
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      };
      var chart = new ApexCharts(item, options);
      chart.render();

      $.ajax({
        url: '/get_data',
        data: {
          chart_name: chartId
        },
        dataType: 'json',
        success(data) {
          if(chartType === "pie") {
            chart.updateOptions({
              series: JSON.parse(data).data[0].data,
              labels: JSON.parse(data).labels
            })
          } else if (chartType === "column") {
            chart.updateOptions({
              series: JSON.parse(data).data,
              xaxis: {
                categories: JSON.parse(data).labels,
              },
            })
          } else if (chartType === "line") {
            chart.updateOptions({
              series: JSON.parse(data).data,
              xaxis: {
                categories: JSON.parse(data).labels
              },
            })
          }
        },
        error(e) {
          toastr.error(
            "Please try it again later.",
            "Something went wrong!",
            {timeOut: 1000, extendedTimeOut: 0, closeButton: true, closeDuration: 0}
          );
        }
      });
      // Render pie with data and then update with retrieve data
    })
    selectChart();
  }

  var populateDropdownSelections = function () {
    let dropdownSelectors = document.querySelectorAll('.select-dropdown-container');
    dropdownSelectors.forEach(function(item, index, nodeList) {
      let value = item.id;

      $(item).children().val(value).trigger('change');
    });
  }

  var deleteRow = function () {
    let buttons = document.querySelectorAll('[data-row-dismiss]');
    buttons.forEach(function(button, index, nodeList) {
      $(button).on('click', function(event) {
        let rowIndex = event.currentTarget.dataset.rowDismiss
        let elements = document.querySelectorAll(`[data-row-index="${rowIndex}"]`)
        console.log("element")
        console.log(elements)
        elements.forEach(
          function(element) {
            element.remove();
          }
        )
        // element.remove();
      });
    });
  }

  var updateDashboard = function () {
    let button = document.getElementById("kt_dashboard_update");
    if(!button) {
      return;
    }
    let buttonClasses = button.classList;
    let dashboardId = "0";
    buttonClasses.forEach(function(item, index, nodeList) {
      if(item.includes("kt_dashboard_update")) {
        dashboardId = item.split("_").splice(-1)[0]
      }
    })
    $(button).on('click', function(event) {
      event.preventDefault();
      let rows = document.querySelectorAll('[id^="dashboardRow_"]')
      let dashboardLayout = []
      rows.forEach(function(item, index, nodeList) {
        let rowIndex = item.id.split("_").splice(-1)[0]
        let rowType = item.id.split("_")[1]
        let chartType = $(item.querySelectorAll(".apex-chart-container")[0]).children()[0].dataset.type
        if(rowType === 'single') {
          let cardBodyId = item.querySelectorAll(".card-chart")[0].id.split("-")
          let chartId = cardBodyId
          let chartName = item.querySelectorAll("select.form-select")[0].value
          if(cardBodyId.length > 1) {
            chartId = cardBodyId.splice(-1)[0]
          } else {
            chartId = cardBodyId[0]
          }
          let column = 1
          dashboardLayout.push({
            row_index: rowIndex,
            row_type: rowType,
            selected_chart: chartId,
            column: column,
            chart_type: chartType,
            chart_name: chartName
          })
        } else {
          // TODO: make row layouts for double together 
          let columns = item.querySelectorAll(".chart-container")
          let selectedCharts = []
          columns.forEach(function(columnItem, index, nodeList) {
            let cardBodyId = $(columnItem).children()[1].id.split("-")
            let chartId = cardBodyId
            let chartType = $(columnItem.querySelectorAll(".apex-chart-container")[0]).children()[0].dataset.type
            let chartName = item.querySelectorAll("select.form-select")[index].value
            if(cardBodyId.length > 1) {
              chartId = cardBodyId.splice(-1)[0]
            } else {
              chartId = cardBodyId[0]
            }
            let column = columnItem.id.split("-")[1]
            selectedCharts.push({
              chart: chartId,
              column: column,
              chart_type: chartType,
              chart_name: chartName
            })
          })
          dashboardLayout.push({
            row_index: rowIndex,
            row_type: rowType,
            selected_charts: selectedCharts
          })
        }
      })

      let dashboardTitle = $("#dashboard_title").val()

      $.ajax({
        url: '/dashboards/' + dashboardId,
        method: 'PATCH',
        data: {
          title: dashboardTitle,
          layout: dashboardLayout
        },
        success(data) {
        },
        error(e) {
          toastr.error(
						"Please try it again later.",
						"Something went wrong!",
						{timeOut: 1000, extendedTimeOut: 0, closeButton: true, closeDuration: 0}
					);
        }
      });
    })
  }

	return {
		// Public functions
		init: function () {
      displaySelectedCharts();
      populateDropdownSelections();
      deleteRow();
      enableSaveButton();
      addDashboardRow();
      saveDashboard();
      updateDashboard();
		}
	};
}();

// On document ready
KTUtil.onDOMContentLoaded(function () {
	KTDashboards.init();
});
