"use strict";

// Class definition
var Users = function () {
	var submitAboutForm = function () {
		let button = document.getElementById('about_user_submit');
		if(!button) {
			return
		};

		$(button).on('click', function(event) {
			let userId = $('#about_user_submit')[0].dataset.id;
			let role = $('#user_role').val();
			let checkedTools = $('.tools-checkbox:checked');
			let tools = []
			checkedTools.each(function() {
				tools.push($(this).val());
			});
			tools.push($('#flexCheckOther').val());
			let checkedProblems = $('.problem-checkbox:checked');
			let problems = []
			checkedProblems.each(function() {
				problems.push($(this).val());
			});
			problems.push($('#flexProblemOther').val());

			console.log($('#about_user_submit')[0].dataset.id)
			console.log('tools')
			console.log(tools)
			$.ajax({
				url: '/about',
				method: 'POST',
				data: {
					role: role,
					tools: tools,
					problems: problems
				},
				success(data) {
				},
				error(e) {
					toastr.error(
						"Please try it again later.",
						"Something went wrong!",
						{timeOut: 1000, extendedTimeOut: 0, closeButton: true, closeDuration: 0}
					);
				}
			})
		})
	}

  var changeProjectActivation = function () {
		let buttons = document.querySelectorAll('.activate-project-checkbox');
    buttons.forEach(function(button, index, nodeList) {
      $(button).on('click', function(event) {
				$.ajax({
					url: '/projects/' + event.currentTarget.value + '/update_activated',
					method: 'POST',
					data: {
						project_id: event.currentTarget.value,
						integration: button.dataset.integration
					},
					success(data) {
					},
					error(e) {
						toastr.error(
							"Please try it again later.",
							"Something went wrong!",
							{timeOut: 1000, extendedTimeOut: 0, closeButton: true, closeDuration: 0}
						);
					}
				});
      });
    });
  }

	var dismissExtendedPeriod = function () {
		let element = document.getElementById('extended-trial-dismiss');

		if(!element) {
			return;
		}

		element.onclick = function(event) {
			let userId = element.dataset.userId

			$.ajax({
				url: '/users/' + userId + '/dismiss_extended_trial',
				method: 'POST',
				success(data) {

				},
				error(e) {
					toastr.error(
						"Please try it again later.",
						"Something went wrong!",
						{timeOut: 1000, extendedTimeOut: 0, closeButton: true, closeDuration: 0}
					);
				}
			})
		}
	}

	var dismissReportNotification = function () {
		let element = document.getElementById('report-notification-dismiss');

		if(!element) {
			return;
		}

		element.onclick = function(event) {
			let userId = element.dataset.userId

			$.ajax({
				url: '/users/' + userId + '/dismiss_report_notification',
				method: 'POST',
				success(data) {
				},
				error(e) {
					toastr.error(
						"Please try it again later.",
						"Something went wrong!",
						{timeOut: 1000, extendedTimeOut: 0, closeButton: true, closeDuration: 0}
					);
				}
			})
		}
	}

	return {
		// Public functions
		init: function () {
			submitAboutForm();
      changeProjectActivation();
			dismissExtendedPeriod();
			dismissReportNotification();
		}
	};
}();

// On document ready
KTUtil.onDOMContentLoaded(function () {
	Users.init();
});
