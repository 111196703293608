"use strict";

import moment from 'moment';
import ApexCharts from 'apexcharts';

// Class definition
var KTBurnupChart = function () {
  var apexChart;
  var element;
  var renderChart = function() {
    // let element = document.getElementById('burnup_chart');

    let data = JSON.parse(element.dataset.data);
    var xaxisCategories = JSON.parse(element.dataset.xaxisCategories);
    var labelColor = KTUtil.getCssVariableValue('--bs-gray-500');
    if (!element) {
        return;
    }

    var options = {
      series: [{
        name: "Tasks",
        data: data
      }],
        chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      xaxis: {
        categories: xaxisCategories,
        title: {
          text: 'Sprint',
        },
        labels: {
          style: {
              colors: labelColor,
              fontSize: '12px'
          }
        }
      },
      yaxis: {
        // min: 0,
        title: {
          text: 'Tasks completed'
        },
        labels: {
          style: {
              colors: labelColor,
              fontSize: '12px'
          }
        }
      }
    };
  
    var chart = new ApexCharts(element, options);
    chart.render();
    apexChart = chart;
  }

	return {
		// Public functions
		init: function () {
      element = document.getElementById('burnup_chart');

      if(element) {
        renderChart();
      }
		}
	};
}();

// On document ready
KTUtil.onDOMContentLoaded(function () {
	KTBurnupChart.init();
});
