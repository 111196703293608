"use strict";

import ApexCharts from 'apexcharts';

// Class definition
var KTVelocityChart = function () {
  var apexChart;
  
  var renderChart = function() {
    let element = document.getElementById('velocity_chart');

    let data = JSON.parse(element.dataset.data);
    console.log('data in javascript')
    console.log(data)
    var xaxisCategories = JSON.parse(element.dataset.xaxisCategories);
    var height = parseInt(KTUtil.css(element, 'height'));
    var labelColor = KTUtil.getCssVariableValue('--bs-gray-500');
    var borderColor = KTUtil.getCssVariableValue('--bs-gray-200');
    var baseColor = KTUtil.getCssVariableValue('--bs-primary');
    var secondaryColor = KTUtil.getCssVariableValue('--bs-gray-300');
  
    if (!element) {
        return;
    }
  
    var options = {
      series: [{
        name: 'Estimated',
        // data: [7, 8, 9, 6, 7, 7, 8, 9, 5, 9]
        data: data[0]
      }, {
        name: 'Completed',
        // data: [7, 7, 10, 6, 6, 7, 8, 8, 6, 9]
        data: data[1]
      }],
        chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: xaxisCategories,
        title: {
          text: 'Sprint',
        },
        labels: {
          style: {
              colors: labelColor,
              fontSize: '12px'
          }
        }
      },
      yaxis: {
        title: {
          text: ''
        }
      },
      fill: {
        opacity: 1
      }
    };
  
    var chart = new ApexCharts(element, options);
    chart.render();
    apexChart = chart;
  }

	return {
		// Public functions
		init: function () {
      let element = document.getElementById('velocity_chart');

      if(element) {
        renderChart();
      }
		}
	};
}();

// On document ready
KTUtil.onDOMContentLoaded(function () {
	KTVelocityChart.init();
});
